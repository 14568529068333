var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"771","rounded":"lg","persistent":"","attach":".admin-offer-tool-settings","hide-overlay":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"base-dialog offer-tool-settings-form-dialog"},[_c('v-card-title',{staticClass:"headline"},[_c('h2',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])]),_c('v-card-text',[_c('div',{staticClass:"settings-form"},[_c('table',[_c('tbody',[_c('tr',[_c('td'),_c('td',[(_vm.errorMessage)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e()])]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.tax ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.vat'))+" ")])]),_c('td',[(_vm.inputError.tax)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.tax)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.tax),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "tax", $$v)},expression:"editOfferToolSettings.tax"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.round ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.rounding_up'))+" ")])]),_c('td',[(_vm.inputError.round)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.round)}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.roundUpOptions,"item-text":"label","item-value":"value","solo":"","hide-details":""},on:{"input":function($event){_vm.inputErrorMessage.round = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icon-caret')]},proxy:true}]),model:{value:(_vm.editOfferToolSettings.round),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "round", $$v)},expression:"editOfferToolSettings.round"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.alloyMountCost ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.pcr_alloy_mounting'))+" ")])]),_c('td',[(_vm.inputError.alloyMountCost)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.alloyMountCost)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.alloyMountCost),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "alloyMountCost", $$v)},expression:"editOfferToolSettings.alloyMountCost"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.steelMountCost ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.pcr_steel_mounting'))+" ")])]),_c('td',[(_vm.inputError.steelMountCost)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.steelMountCost)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.steelMountCost),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "steelMountCost", $$v)},expression:"editOfferToolSettings.steelMountCost"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.heavyTyreAlloyMountCost ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.truck_tyre_alloy_mounting'))+" ")])]),_c('td',[(_vm.inputError.heavyTyreAlloyMountCost)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.heavyTyreAlloyMountCost)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.heavyTyreAlloyMountCost),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "heavyTyreAlloyMountCost", $$v)},expression:"editOfferToolSettings.heavyTyreAlloyMountCost"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.heavyTyreSteelMountCost ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.truck_tyre_steel_mounting'))+" ")])]),_c('td',[(_vm.inputError.heavyTyreSteelMountCost)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.heavyTyreSteelMountCost)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.heavyTyreSteelMountCost),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "heavyTyreSteelMountCost", $$v)},expression:"editOfferToolSettings.heavyTyreSteelMountCost"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.tpmsSensor ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.dealer-calculation-tpms-sensor'))+" ")])]),_c('td',[(_vm.inputError.tpmsSensor)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.tpmsSensor)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.tpmsSensor),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "tpmsSensor", $$v)},expression:"editOfferToolSettings.tpmsSensor"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.tpmsProgramming ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.tpms_programming'))+" ")])]),_c('td',[(_vm.inputError.tpmsProgramming)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.tpmsProgramming)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.tpmsProgramming),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "tpmsProgramming", $$v)},expression:"editOfferToolSettings.tpmsProgramming"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.valveRubber ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.valve_rubber'))+" ")])]),_c('td',[(_vm.inputError.valveRubber)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.valveRubber)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.valveRubber),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "valveRubber", $$v)},expression:"editOfferToolSettings.valveRubber"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.valveMetal ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.valve_metal'))+" ")])]),_c('td',[(_vm.inputError.valveMetal)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.valveMetal)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.valveMetal),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "valveMetal", $$v)},expression:"editOfferToolSettings.valveMetal"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.heave ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.heave'))+" ")])]),_c('td',[(_vm.inputError.heave)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.heave)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.heave),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "heave", $$v)},expression:"editOfferToolSettings.heave"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.adhesiveWeightFlatRate ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.adhesive_weight_flat_rate'))+" ")])]),_c('td',[(_vm.inputError.adhesiveWeightFlatRate)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.adhesiveWeightFlatRate)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.adhesiveWeightFlatRate),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "adhesiveWeightFlatRate", $$v)},expression:"editOfferToolSettings.adhesiveWeightFlatRate"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.impactWeightFlatRate ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.impact_weight_flat_rate'))+" ")])]),_c('td',[(_vm.inputError.impactWeightFlatRate)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.impactWeightFlatRate)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.impactWeightFlatRate),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "impactWeightFlatRate", $$v)},expression:"editOfferToolSettings.impactWeightFlatRate"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.wheelMount ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.wheel_mount'))+" ")])]),_c('td',[(_vm.inputError.wheelMount)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.wheelMount)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.wheelMount),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "wheelMount", $$v)},expression:"editOfferToolSettings.wheelMount"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.wheelWash ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.wheel_wash'))+" ")])]),_c('td',[(_vm.inputError.wheelWash)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.wheelWash)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.wheelWash),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "wheelWash", $$v)},expression:"editOfferToolSettings.wheelWash"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.warehousing ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.warehousing'))+" ")])]),_c('td',[(_vm.inputError.warehousing)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.warehousing)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.warehousing),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "warehousing", $$v)},expression:"editOfferToolSettings.warehousing"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.freightCharges ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.freight_charges'))+" ")])]),_c('td',[(_vm.inputError.freightCharges)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.freightCharges)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.freightCharges),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "freightCharges", $$v)},expression:"editOfferToolSettings.freightCharges"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.tyreDisposal ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.tyre_disposal'))+" ")])]),_c('td',[(_vm.inputError.tyreDisposal)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.tyreDisposal)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.tyreDisposal),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "tyreDisposal", $$v)},expression:"editOfferToolSettings.tyreDisposal"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.wheelAlignment ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.wheel_alignment'))+" ")])]),_c('td',[(_vm.inputError.wheelAlignment)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.wheelAlignment)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.editOfferToolSettings.wheelAlignment),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "wheelAlignment", $$v)},expression:"editOfferToolSettings.wheelAlignment"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.offerLogo ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.offer_logo'))+" ")])]),_c('td',[_c('v-file-input',{ref:"offerLogoInput",attrs:{"placeholder":`${_vm.$t('langkey.browse')}`,"accept":"image/*","prepend-icon":"","clearable":false,"hide-details":""},on:{"change":_vm.onFileChange},model:{value:(_vm.offerLogoValue),callback:function ($$v) {_vm.offerLogoValue=$$v},expression:"offerLogoValue"}})],1)]),_c('tr',[_c('td',{staticClass:"pb-0"}),_c('td',{staticClass:"pb-0"},[_c('p',{staticClass:"offer-logo-help"},[_vm._v(" "+_vm._s(_vm.$t('langkey.offer_logo.help'))+" ")])])]),_c('tr',[_c('td'),_c('td',[(_vm.editOfferLogo)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin-top":"8px"}},[_c('img',{staticClass:"offer-logo-img",attrs:{"src":_vm.editOfferLogo}}),_c('a',{staticClass:"delete",on:{"click":_vm.deleteOfferLogo}},[_vm._v(" "+_vm._s(_vm.$t('langkey.delete'))+" ")])]):_vm._e()])]),_c('tr',[_c('td',[_c('span',{class:[
                      _vm.inputError.offerClosingText ?
                        'error-message' : ''
                    ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.offer_closing_text'))+" ")])]),_c('td',[(_vm.inputError.offerClosingText)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.offerClosingText)}}):_vm._e(),_c('v-textarea',{attrs:{"solo":"","hide-details":""},model:{value:(_vm.editOfferToolSettings.offerClosingText),callback:function ($$v) {_vm.$set(_vm.editOfferToolSettings, "offerClosingText", $$v)},expression:"editOfferToolSettings.offerClosingText"}})],1)])])])])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onBack}},[_vm._v(" "+_vm._s(_vm.$t('langkey.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"icon",attrs:{"color":"accent","disabled":!_vm.hasSettingsChange && !_vm.hasLogoChange},on:{"click":_vm.saveSettings}},[_vm._v(" "+_vm._s(_vm.$t('langkey.save'))+" ")])],1)],1)],1),(_vm.showDialog)?_c('v-overlay',{attrs:{"absolute":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }