<template>
  <section class="admin-offer-tool-settings">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>{{ $t('langkey.offer-tool-settings') }}</h1>
        <p class="description" />
      </div>
    </v-container>

    <offer-tool-settings-form-dialog
      ref="offerToolSettingsFormDialog"
      :title="$t('langkey.offer-tool-settings')"
      :offer-tool-settings="offerToolSettings"
      @save-settings="saveSettings"
    />
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-offer-tool-settings.scss";
</style>

<script>
import OfferToolSettingsFormDialog from '@/components/OfferToolSettingsFormDialog';
import { mapState } from 'vuex';

export default {
  components: {
    OfferToolSettingsFormDialog,
  },
  computed: {
    ...mapState('dealerCalculation', ['offerToolSettings']),
  },
  mounted() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: true,
    });
    this.$store.dispatch('dealerCalculation/getOfferToolSettings')
      .then(() => {
        this.$refs.offerToolSettingsFormDialog.show();
      })
      .finally(() => {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      });
  },
  methods: {
    saveSettings() {
      this.$store.dispatch('dealerCalculation/getOfferToolSettings');
    },
  },
};
</script>
