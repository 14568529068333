<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="771"
      rounded="lg"
      persistent
      attach=".admin-offer-tool-settings"
      hide-overlay
    >
      <v-card class="base-dialog offer-tool-settings-form-dialog">
        <v-card-title
          class="headline"
        >
          <h2
            class="title-text"
          >
            {{ $t(title) }}
          </h2>
        </v-card-title>
        <v-card-text>
          <div class="settings-form">
            <table>
              <tbody>
                <tr>
                  <td />
                  <td>
                    <p
                      v-if="errorMessage"
                      class="error-message"
                      v-html="errorMessage"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.tax ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.vat') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.tax"
                      class="error-message"
                      v-html="inputErrorMessage.tax"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.tax"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.round ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.rounding_up') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.round"
                      class="error-message"
                      v-html="inputErrorMessage.round"
                    />
                    <v-select
                      v-model="editOfferToolSettings.round"
                      :items="roundUpOptions"
                      item-text="label"
                      item-value="value"
                      solo
                      hide-details
                      @input="inputErrorMessage.round = ''"
                    >
                      <template #append>
                        <icon-caret />
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.alloyMountCost ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.pcr_alloy_mounting') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.alloyMountCost"
                      class="error-message"
                      v-html="inputErrorMessage.alloyMountCost"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.alloyMountCost"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.steelMountCost ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.pcr_steel_mounting') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.steelMountCost"
                      class="error-message"
                      v-html="inputErrorMessage.steelMountCost"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.steelMountCost"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.heavyTyreAlloyMountCost ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.truck_tyre_alloy_mounting') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.heavyTyreAlloyMountCost"
                      class="error-message"
                      v-html="inputErrorMessage.heavyTyreAlloyMountCost"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.heavyTyreAlloyMountCost"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.heavyTyreSteelMountCost ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.truck_tyre_steel_mounting') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.heavyTyreSteelMountCost"
                      class="error-message"
                      v-html="inputErrorMessage.heavyTyreSteelMountCost"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.heavyTyreSteelMountCost"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.tpmsSensor ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.dealer-calculation-tpms-sensor') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.tpmsSensor"
                      class="error-message"
                      v-html="inputErrorMessage.tpmsSensor"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.tpmsSensor"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.tpmsProgramming ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.tpms_programming') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.tpmsProgramming"
                      class="error-message"
                      v-html="inputErrorMessage.tpmsProgramming"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.tpmsProgramming"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.valveRubber ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.valve_rubber') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.valveRubber"
                      class="error-message"
                      v-html="inputErrorMessage.valveRubber"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.valveRubber"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.valveMetal ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.valve_metal') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.valveMetal"
                      class="error-message"
                      v-html="inputErrorMessage.valveMetal"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.valveMetal"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.heave ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.heave') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.heave"
                      class="error-message"
                      v-html="inputErrorMessage.heave"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.heave"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.adhesiveWeightFlatRate ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.adhesive_weight_flat_rate') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.adhesiveWeightFlatRate"
                      class="error-message"
                      v-html="inputErrorMessage.adhesiveWeightFlatRate"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.adhesiveWeightFlatRate"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.impactWeightFlatRate ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.impact_weight_flat_rate') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.impactWeightFlatRate"
                      class="error-message"
                      v-html="inputErrorMessage.impactWeightFlatRate"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.impactWeightFlatRate"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.wheelMount ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.wheel_mount') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.wheelMount"
                      class="error-message"
                      v-html="inputErrorMessage.wheelMount"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.wheelMount"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.wheelWash ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.wheel_wash') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.wheelWash"
                      class="error-message"
                      v-html="inputErrorMessage.wheelWash"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.wheelWash"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.warehousing ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.warehousing') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.warehousing"
                      class="error-message"
                      v-html="inputErrorMessage.warehousing"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.warehousing"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.freightCharges ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.freight_charges') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.freightCharges"
                      class="error-message"
                      v-html="inputErrorMessage.freightCharges"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.freightCharges"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.tyreDisposal ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.tyre_disposal') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.tyreDisposal"
                      class="error-message"
                      v-html="inputErrorMessage.tyreDisposal"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.tyreDisposal"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.wheelAlignment ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.wheel_alignment') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.wheelAlignment"
                      class="error-message"
                      v-html="inputErrorMessage.wheelAlignment"
                    />
                    <v-text-field
                      v-model="editOfferToolSettings.wheelAlignment"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.offerLogo ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.offer_logo') }}
                    </span>
                  </td>
                  <td>
                    <v-file-input
                      ref="offerLogoInput"
                      v-model="offerLogoValue"
                      :placeholder="`${$t('langkey.browse')}`"
                      accept="image/*"
                      prepend-icon=""
                      :clearable="false"
                      hide-details
                      @change="onFileChange"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="pb-0" />
                  <td class="pb-0">
                    <p class="offer-logo-help">
                      {{ $t('langkey.offer_logo.help') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <div
                      v-if="editOfferLogo"
                      class="d-flex align-center"
                      style="margin-top: 8px"
                    >
                      <img
                        class="offer-logo-img"
                        :src="editOfferLogo"
                      >
                      <a
                        class="delete"
                        @click="deleteOfferLogo"
                      >
                        {{ $t('langkey.delete') }}
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.offerClosingText ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.offer_closing_text') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.offerClosingText"
                      class="error-message"
                      v-html="inputErrorMessage.offerClosingText"
                    />
                    <v-textarea
                      v-model="editOfferToolSettings.offerClosingText"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="onBack"
          >
            {{ $t('langkey.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="accent"
            class="icon"
            :disabled="!hasSettingsChange && !hasLogoChange"
            @click="saveSettings"
          >
            {{ $t('langkey.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      v-if="showDialog"
      absolute
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/components/offer-tool-settings-form-dialog.scss";
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import { roundUpOptions } from '@/pages/admin/offer-tool-settings/config.js';
import _ from 'lodash';

export default {
  components: {
    IconCaret,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    offerToolSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDialog: false,
      errorMessage: '',
      editOfferToolSettings: {
        tax: '',
        alloyMountCost: '',
        steelMountCost: '',
        heavyTyreAlloyMountCost: '',
        heavyTyreSteelMountCost: '',
        tpmsSensor: '',
        tpmsProgramming: '',
        valveRubber: '',
        valveMetal: '',
        heave: '',
        adhesiveWeightFlatRate: '',
        impactWeightFlatRate: '',
        wheelMount: '',
        wheelWash: '',
        warehousing: '',
        freightCharges: '',
        tyreDisposal: '',
        wheelAlignment: '',
        offerClosingText: '',
        round: '',
      },
      offerLogoValue: [],
      editOfferLogo: '',
      editOfferLogoFile: null,
      inputError: {
        tax: false,
        alloyMountCost: false,
        steelMountCost: false,
        heavyTyreAlloyMountCost: false,
        heavyTyreSteelMountCost: false,
        tpmsSensor: false,
        tpmsProgramming: false,
        valveRubber: false,
        valveMetal: false,
        heave: false,
        adhesiveWeightFlatRate: false,
        impactWeightFlatRate: false,
        wheelMount: false,
        wheelWash: false,
        warehousing: false,
        freightCharges: false,
        tyreDisposal: false,
        wheelAlignment: false,
        offerClosingText: false,
        round: false,
        offerLogo: false,
      },
      inputErrorMessage: {
        tax: '',
        alloyMountCost: '',
        steelMountCost: '',
        heavyTyreAlloyMountCost: '',
        heavyTyreSteelMountCost: '',
        tpmsSensor: '',
        tpmsProgramming: '',
        valveRubber: '',
        valveMetal: '',
        heave: '',
        adhesiveWeightFlatRate: '',
        impactWeightFlatRate: '',
        wheelMount: '',
        wheelWash: '',
        warehousing: '',
        freightCharges: '',
        tyreDisposal: '',
        wheelAlignment: '',
        offerClosingText: '',
        round: '',
      },
      roundUpOptions: roundUpOptions,
    };
  },
  computed: {
    hasSettingsChange() {
      return Object.keys(this.editOfferToolSettings).some((key) => {
        return this.editOfferToolSettings[key] !== this.offerToolSettings[key];
      });
    },
    hasLogoChange() {
      return this.editOfferLogo !== this.offerToolSettings.offerLogo;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: false,
    });
  },
  methods: {
    show() {
      Object.keys(this.editOfferToolSettings).forEach((key) => {
        this.editOfferToolSettings[key] = this.offerToolSettings[key];
      });
      this.editOfferLogo = this.offerToolSettings.offerLogo;
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
      Object.keys(this.editOfferToolSettings).forEach((key) => {
        this.editOfferToolSettings[key] = '';
      });
      this.editOfferLogo = '';
      this.errorMessage = '';
      Object.keys(this.inputError).forEach((key) => {
        this.inputError[key] = false;
      });
      Object.keys(this.inputErrorMessage).forEach((key) => {
        this.inputErrorMessage[key] = '';
      });
    },
    async saveSettings() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.errorMessage = '';
      Object.keys(this.inputError).forEach((key) => {
        this.inputError[key] = false;
      });
      Object.keys(this.inputErrorMessage).forEach((key) => {
        this.inputErrorMessage[key] = '';
      });
      const payload = this.getPayload();
      await this.updateOfferToolSettings(payload);
    },
    async updateOfferToolSettings(payload) {
      try {
        if (this.hasSettingsChange) {
          await this.$store.dispatch('dealerCalculation/updateOfferToolSettings', payload);
        }
        if (this.hasLogoChange) {
          await this.$store.dispatch('dealerCalculation/updateSettingsLogo', {
            offerLogo: this.editOfferLogoFile,
          });
        }
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.save-success'),
          message: '',
        });
        this.$emit('save-settings');
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        Object.keys(this.inputError).forEach((key) => {
          this.inputError[key] = false;
        });
      } catch (err) {
        if (err.response && err.response.status === 500) {
          this.errorMessage = err.response.data;
        } else if (err.response.status === 404) {
          this.errorMessage = err.response.data;
          this.inputError.offerLogo = true;
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    getPayload() {
      let payload = {
        tax: this.editOfferToolSettings.tax || 0,
        alloyMountCost: this.editOfferToolSettings.alloyMountCost || 0,
        steelMountCost: this.editOfferToolSettings.steelMountCost || 0,
        heavyTyreAlloyMountCost: this.editOfferToolSettings.heavyTyreAlloyMountCost || 0,
        heavyTyreSteelMountCost: this.editOfferToolSettings.heavyTyreSteelMountCost || 0,
        tpmsSensor: this.editOfferToolSettings.tpmsSensor || 0,
        tpmsProgramming: this.editOfferToolSettings.tpmsProgramming || 0,
        valveRubber: this.editOfferToolSettings.valveRubber || 0,
        valveMetal: this.editOfferToolSettings.valveMetal || 0,
        heave: this.editOfferToolSettings.heave || 0,
        adhesiveWeightFlatRate: this.editOfferToolSettings.adhesiveWeightFlatRate || 0,
        impactWeightFlatRate: this.editOfferToolSettings.impactWeightFlatRate || 0,
        wheelMount: this.editOfferToolSettings.wheelMount || 0,
        wheelWash: this.editOfferToolSettings.wheelWash || 0,
        warehousing: this.editOfferToolSettings.warehousing || 0,
        freightCharges: this.editOfferToolSettings.freightCharges || 0,
        tyreDisposal: this.editOfferToolSettings.tyreDisposal || 0,
        wheelAlignment: this.editOfferToolSettings.wheelAlignment || 0,
        offerClosingText: this.editOfferToolSettings.offerClosingText || 'string',
        round: this.editOfferToolSettings.round || '10cent',
      };
      return payload;
    },
    async onFileChange(file) {
      this.editOfferLogo = await this.toBase64(file);
      this.editOfferLogoFile = file;
    },
    toBase64(file) {
      if (file && file.type) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
    },
    deleteOfferLogo() {
      this.editOfferLogo = '';
      this.editOfferLogoFile = null;
      this.$refs.offerLogoInput.reset();
    },
    onBack() {
      this.$router.push(this.$i18nRoute({
        name: 'admin-overview',
      }));
    },
  },
};
</script>
